define("discourse/plugins/chat/admin/components/chat-admin-plugin-actions", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _ajaxError, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatAdminPluginActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "chatAdminApi", [_service.service]))();
    #chatAdminApi = (() => (dt7948.i(this, "chatAdminApi"), void 0))();
    confirmExportMessages() {
      return this.dialog.confirm({
        message: _discourseI18n.default.t("chat.admin.export_messages.confirm_export"),
        didConfirm: () => this.exportMessages()
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "confirmExportMessages", [_object.action]))();
    async exportMessages() {
      try {
        await this.chatAdminApi.exportMessages();
        this.dialog.alert(_discourseI18n.default.t("chat.admin.export_messages.export_has_started"));
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "exportMessages", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@actions.Primary
          @label="chat.admin.export_messages.create_export"
          @title="chat.admin.export_messages.create_export"
          @action={{this.confirmExportMessages}}
          @icon="right-from-bracket"
          class="admin-chat-export"
        />
      
    */
    {
      "id": "Tq21meGp",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"Primary\"]],[[24,0,\"admin-chat-export\"]],[[\"@label\",\"@title\",\"@action\",\"@icon\"],[\"chat.admin.export_messages.create_export\",\"chat.admin.export_messages.create_export\",[30,0,[\"confirmExportMessages\"]],\"right-from-bracket\"]],null],[1,\"\\n  \"]],[\"@actions\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/admin/components/chat-admin-plugin-actions.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatAdminPluginActions;
});